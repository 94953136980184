import Vue from 'vue'
import Vuex from 'vuex'
import * as customersAPI from '@/services/customers'
import utilities from '@/services/utilities'
import router from '@/router'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		customersList: [],
		customersListFiltered: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			customersAPI
				.getAll(payload)
				.then((response) => {
					commit('SET_STATE', {
						customersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Clientes',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			return new Promise((resolve, reject) => {
				customersAPI
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualRecord: _.cloneDeep(response.data),
						})
						if (router.currentRoute.params.id != id) {
							router.push(`/customers/${id}`)
						}
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							actualRecord: {},
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, { payload, returnData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
						})
						if (!returnData) {
							dispatch('GET_ONE', response.data.id)
						} else {
							dispatch('GET')
						}
						resolve(response)
					})
					.catch((error) => {
						// console.log('error-->', error)
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						})
						dispatch('GET_ONE', payload.customer_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async TOGGLE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				c.toggle(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: response.message,
						})
						dispatch('GET')

						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async SEARCH({ commit }, { searchText, searchType }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.search({ searchText, searchType })
					.then((response) => {
						commit('SET_STATE', {
							customersList: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		customersList: (state) => state.customersList,
		customersListFiltered: (state) => state.customersListFiltered,
		actualRecord: (state) => state.actualRecord,
	},
}
