import Vue from 'vue'
import Vuex from 'vuex'
import * as taxRegimesAPI from '@/services/taxRegimes'
import utilities from '@/services/utilities'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		taxRegimesList: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			taxRegimesAPI
				.get()
				.then((response) => {
					commit('SET_STATE', {
						taxRegimesList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.taxRegimes'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
					commit('SET_STATE', {
						taxRegimesList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit, state }, id) {
			commit('SET_STATE', {
				actualRecord: _.cloneDeep(state.taxRegimesList.find((e) => e.id == id)),
			})
		},
		async CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				taxRegimesAPI
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.taxRegimes'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.taxRegimes'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		TOGGLE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			taxRegimesAPI
				.toggle(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.taxRegimes'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.taxRegimes'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorDisable')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				taxRegimesAPI
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.taxRegimes'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.taxRegimes'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		taxRegimesList: (state) => state.taxRegimesList,
		actualRecord: (state) => state.actualRecord,
	},
}
