<template>
	<a-layout>
		<a-layout-content>
			<div :class="{
				[$style.container]: true,
				cui__layout__squaredBorders: settings.isSquaredBorders,
				cui__layout__cardsShadow: settings.isCardShadow,
				cui__layout__borderless: settings.isBorderless,
				[$style.white]: settings.authPagesColor === 'white',
				[$style.gray]: settings.authPagesColor === 'gray',
			}" :style="{ backgroundImage: settings.authPagesColor === 'image' ? `url(resources/images/content/photos/5.jpeg)` : 'none' }">
				<div :class="$style.containerInner">
					<transition :name="settings.routerAnimation" mode="out-in">
						<router-view />
					</transition>
				</div>
				<div class="mt-auto pb-5 pt-5">
					<div class="text-center">Todos los Derechos Reservados</div>
					<div class="text-center">v.{{ appVersion }}</div>
				</div>
			</div>
		</a-layout-content>
	</a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AuthLayout',
	components: {},
	computed: mapState(['settings']),
	data() {
		return {
			appVersion: process.env.VUE_APP_VERSION,
		}
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
