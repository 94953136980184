import Vue from 'vue'
import Vuex from 'vuex'
import * as motorcycleModel from '@/services/motorcycle-models'
import utilities from '@/services/utilities'
import _ from 'lodash'
import Swal from 'sweetalert2'

const mapMotorcycleProviders = {
	getModels: motorcycleModel.getModels,
	getOneModel: motorcycleModel.getOneModel,
	getAllModels: motorcycleModel.getAllModels,
	createModel: motorcycleModel.createModel,
	updateModel: motorcycleModel.updateModel,
	enable: motorcycleModel.enable,
	disable: motorcycleModel.disable,
}
Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		motorcycleModelList: [],
		motorcycleModelByBrandList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			mapMotorcycleProviders
				.getAllModels()
				.then((response) => {
					commit('SET_STATE', {
						motorcycleModelList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Catálogo de modelos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						motorcycleModelList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_MODELS_BY_BRAND({ commit }, { brand_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				motorcycleModelByBrandList: [],
			})

			mapMotorcycleProviders
				.getModels(brand_id)
				.then((response) => {
					commit('SET_STATE', {
						motorcycleModelByBrandList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Catálogo de modelos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						motorcycleModelByBrandList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE_MODEL({ commit, dispatch }, { payload, controlTaller }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información...',
			})
			return new Promise((resolve, reject) => {
				mapMotorcycleProviders
					.createModel(payload)
					.then((response) => {
						Swal.fire({
							title: 'Marcas de motocicletas',
							text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
							.then(() => {
								dispatch('GET_MODELS_BY_BRAND', { brand_id: payload.brand_id, controlTaller: false })
							})
							.then(resolve(response))
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Modelos de motocicletas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		UPDATE_MODEL({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información...',
			})

			return mapMotorcycleProviders
				.updateModel(payload)
				.then((success) => {
					if (success) {
						Vue.prototype.$notification.success({
							message: 'CATÁLOGOS - MODELOS DE MOTOCICLETAS',
							description: success.message,
						})
						dispatch('GET')
						return success
					} else {
						return false
					}
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			mapMotorcycleProviders
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Modelos de motocicletas',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Modelos de motocicletas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			mapMotorcycleProviders
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Modelos de motocicletas',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Modelos de motocicletas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		motorcycleModelList: (state) => state.motorcycleModelList,
		motorcycleModelByBrandList: (state) => state.motorcycleModelByBrandList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
