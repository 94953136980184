import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/warehouse/products')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/warehouse/products/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function search(payload) {
	return new Promise((resolve, reject) => {
		///
		return apiClient
			.post(`/warehouse/search`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function create(payload) {
	return new Promise((resolve, reject) => {
		apiClient
			.post('/warehouse/products', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/warehouse/products', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/toggle', { id })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getProductCategories() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/products/categories')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getProductSubCategories() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/products/subcategories')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateBySKU(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/update/sku', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createOutboundOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/products/outbound-orders', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggleCategory(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/toggle/category', { id })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggleSubcategory(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/toggle/subcategory', { id })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createCategory(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/products/category', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createSubcategory(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/products/subcategory', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateCategory(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/category', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateSubcategory(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/products/subcategory', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function deleteImage(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/warehouse/products/images/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function searchOutboundOrder(searchText) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/warehouse/search-outbound-order`, { searchText })
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateOutboundOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/warehouse/outbound-order`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOutboundOrder(id, fromWarehouse) {
	return new Promise((resolve, reject) => {
		if (fromWarehouse) {
			return apiClient
				.get(`/warehouse/outbound-order-warehouse/${id}`)
				.then((response) => {
					resolve(utilities.objectValidate(response, 'data', {}))
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		} else {
			return apiClient
				.get(`/warehouse/outbound-order/${id}`)
				.then((response) => {
					resolve(utilities.objectValidate(response, 'data', {}))
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		}
	})
}

export async function getOpenOutboundOrders() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/warehouse/open-outbound-orders`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
