import Vue from 'vue'
import * as api from '@/services/fuelControl'
import utilities from '@/services/utilities'
import moment from 'moment'
const dateFormat = 'YYYY-MM-DD'

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información...',
		fuelControlList: [],
		filters: {
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			return new Promise((resolve, reject) => {
				api
					.get()
					.then((response) => {
						commit('SET_STATE', {
							fuelControlList: response.data,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async FUEL_ALLOCATION({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			return new Promise((resolve, reject) => {
				api
					.fuelAllocation(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		RESET_ONE_FILTER({ commit, state, dispatch }, key) {
			let filters = {
				...state.filters,
			}

			if (key == 'defaultDate') {
				filters.defaultDate = [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)]
				dispatch('GET_FLOW', {
					startDate: filters.defaultDate[0],
					endDate: filters.defaultDate[1],
				})
			} else {
				filters[key] = 'Todos'
			}

			commit('SET_STATE', {
				filters: _.cloneDeep(filters),
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		fuelControlList: (state) => state.fuelControlList,
		filters: (state) => state.filters,
	},
}
