import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/designs')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getByModel(modelID) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/designs/model/${modelID}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/designs/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function uploadFile(field, payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/designs/file`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/designs`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/designs`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function disable(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/designs/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
