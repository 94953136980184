import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getBrands() {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:getBrands')
		return apiClient
			.get('/brands')
			.then((response) => {
				// console.log('actions:motorcycles:getBrands:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOneBrand(motorcycle_brand_id) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:getOneBrand', brand_id)
		return apiClient
			.get(`/brands/${motorcycle_brand_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function enable(brand_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/brands/enable/${brand_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function disable(brand_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/brands/disable/${brand_id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createBrand(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/brands`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateBrand(payload) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:updateBrand', brand_id)
		return apiClient
			.put(`/brands`, payload)
			.then((response) => {
				// console.log('actions:motorcycles:updateBrand:response', response.data)
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
