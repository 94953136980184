import Vue from 'vue'
import Vuex from 'vuex'
import * as motorcycleBrand from '@/services/motorcycle-brands'
import * as tallerAPI from '@/services/motorcycle-brands/taller'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

const mapMotorcycleProviders = {
	getBrands: motorcycleBrand.getBrands,
	getOneBrand: motorcycleBrand.getOneBrand,
	createBrand: motorcycleBrand.createBrand,
	updateBrand: motorcycleBrand.updateBrand,
	enable: motorcycleBrand.enable,
	disable: motorcycleBrand.disable,
}

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		motorcycleBrandList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_BRANDS({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
				motorcycleBrandList: [],
			})

			mapMotorcycleProviders
				.getBrands()
				.then((response) => {
					commit('SET_STATE', {
						motorcycleBrandList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Catálogo de marcas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE_BRAND({ commit, dispatch, rootState }, id) {
			let motorcycleBrand = mapMotorcycleProviders.getOneBrand

			commit(
				'SET_STATE',
				{
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo datos...',
				},
				{ root: true },
			)

			return motorcycleBrand(id).then((success) => {
				// console.log('motorcycles::success-->', success)
				if (success) {
					commit(
						'SET_STATE',
						{
							spinnerLoader: false,
							spinnerLoaderLabel: '',
						},
						{ root: true },
					)
					return success
				} else {
					return false
				}
			})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			mapMotorcycleProviders
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Marcas de motocicletas',
						description: response.message,
					})
					dispatch('GET_BRANDS')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Marcas de motocicletas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			mapMotorcycleProviders
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Marcas de motocicletas',
						description: response.message,
					})
					dispatch('GET_BRANDS')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Marcas de motocicletas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE_BRAND({ commit, dispatch }, { payload }) {
			return new Promise((resolve, reject) => {
				mapMotorcycleProviders
					.createBrand(payload)
					.then((response) => {
						Swal.fire({
							title: 'Marcas de motocicletas',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							dispatch('GET_BRANDS')
							resolve(response)
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Marcas de motocicletas',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		UPDATE_BRAND({ commit, dispatch, rootState }, { payload }) {
			// console.log('UPDATE_BRAND::payload-->', payload)
			const { id, name } = payload
			const thePayload = { id, name }

			mapMotorcycleProviders
				.updateBrand(thePayload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Marcas de motocicletas',
						description: response.message,
					})
					dispatch('GET_BRANDS')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Marcas de motocicletas',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		motorcycleBrandList: (state) => state.motorcycleBrandList,
	},
}
