<template>
	<a-layout-sider
		:width="settings.leftMenuWidth"
		:collapsible="settings.isMobileView ? false : true"
		:collapsed="settings.isMenuCollapsed && !settings.isMobileView"
		@collapse="onCollapse"
		:class="{
			[$style.menu]: true,
			[$style.white]: settings.menuColor === 'white',
			[$style.gray]: settings.menuColor === 'gray',
			[$style.dark]: settings.menuColor === 'dark',
			[$style.unfixed]: settings.isMenuUnfixed,
			[$style.shadow]: settings.isMenuShadow,
		}"
	>
		<div
			:class="$style.menuOuter"
			:style="{
				width: settings.isMenuCollapsed && !settings.isMobileView ? '80px' : settings.leftMenuWidth + 'px',
				height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
			}"
		>
			<vue-custom-scrollbar
				:style="{
					height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
				}"
				style="overflow: auto"
			>
				<a-menu
					forceSubMenuRender
					:inlineCollapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
					:mode="'inline'"
					:selectedKeys="selectedKeys"
					:openKeys.sync="openKeys"
					@click="handleClick"
					@openChange="handleOpenChange"
					:inlineIndent="15"
					:class="$style.navigation"
				>
					<template v-for="(item, index) in menuData">
						<template v-if="!item.roles || item.roles.includes(user.role)">
							<a-menu-item-group :key="index" v-if="item.category">
								<template slot="title">{{ item.title }}</template>
							</a-menu-item-group>
							<item v-if="!item.children && !item.category" :menu-info="item" :styles="$style" :key="item.key" />
							<sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key" />
						</template>
					</template>
				</a-menu>
			</vue-custom-scrollbar>
		</div>
	</a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import SubMenu from './partials/submenu'
import Item from './partials/item'
import _ from 'lodash'

let initialMenu = {
	category: true,
	title: 'Menú Principal',
}

export default {
	name: 'menu-left',
	components: { vueCustomScrollbar, SubMenu, Item },
	computed: {
		...mapState(['settings']),
		...mapGetters('user', ['user']),
		...mapGetters('users', ['menu']),
	},
	mounted() {
		if (this.user.is_admin) {
			this.createMenuAdmin()
		} else {
			this.createMenuUser()
		}
		this.openKeys = store.get('app.menu.openedKeys') || []
		this.selectedKeys = store.get('app.menu.selectedKeys') || []
		this.setSelectedKeys()
	},
	data() {
		return {
			menuData: [],
			selectedKeys: [],
			openKeys: [],
		}
	},
	watch: {
		'settings.isMenuCollapsed'() {
			this.openKeys = []
		},
		$route() {
			this.setSelectedKeys()
		},
		menu() {
			if (this.user.is_admin) {
				this.createMenuAdmin()
			} else {
				this.createMenuUser()
			}
		},
	},
	methods: {
		onCollapse: function (collapsed, type) {
			const value = !this.settings.isMenuCollapsed
			this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
		},
		handleClick(e) {
			if (e.key === 'settings') {
				this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
				return
			}
			store.set('app.menu.selectedKeys', [e.key])
			this.selectedKeys = [e.key]
		},
		handleOpenChange(openKeys) {
			store.set('app.menu.openedKeys', openKeys)
			this.openKeys = openKeys
		},
		setSelectedKeys() {
			const pathname = this.$route.path
			const menuData = this.menuData.concat()
			const flattenItems = (items, key) =>
				items.reduce((flattenedItems, item) => {
					flattenedItems.push(item)
					if (Array.isArray(item[key])) {
						return flattenedItems.concat(flattenItems(item[key], key))
					}
					return flattenedItems
				}, [])
			const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
			this.selectedKeys = selectedItem ? [selectedItem.key] : []
		},
		createMenuAdmin() {
			//
			let menu = []
			menu.push(initialMenu)

			Object.keys(this.menu).forEach((key) => {
				let actualMenu = {}
				let submenus = Object.keys(this.menu[key])
				// console.log({ actualMenu, submenus });

				actualMenu = {
					title: this.menu[key][0].menu_title,
					key,
					icon: this.menu[key][0].menu_icon,
					url: this.menu[key][0].menu_url,
				}

				if (submenus.length) {
					//
					actualMenu = {
						...actualMenu,
						submenus: [],
					}

					submenus.forEach((submenuKey) => {
						actualMenu.submenus.push({
							title: this.menu[key][submenuKey].module_title,
							key: `${this.menu[key][0].module_key}_${this.menu[key][submenuKey].module_key}`,
							icon: this.menu[key][submenuKey].module_icon,
							url: this.menu[key][submenuKey].module_url,
							require_auth: false,
						})
					})
				}
				// console.log('actualMenu-->', actualMenu);
				menu.push(actualMenu)
			})
			this.$store.commit('CHANGE_SETTING', { setting: 'menu', value: menu })
			this.menuData = _.cloneDeep(menu)
		},
		createMenuUser() {
			let menu = []
			menu.push(initialMenu)

			Object.keys(this.user.permissions).map((key) => {
				let actualMenu = {}
				let submenus = Object.keys(this.user.permissions[key])

				if (submenus.length > 1) {
					actualMenu = {
						title: this.menu[key][0].menu_title,
						key,
						icon: this.menu[key][0].menu_icon,
						url: this.menu[key][0].menu_url,
						submenus: [],
					}

					submenus.forEach((submenuKey) => {
						// validamos si el módulo tiene permisos de lectura
						if (this.user.permissions[key][submenuKey]) {
							let indexOfMenu = this.menu[key].findIndex((element) => element.module_key == submenuKey)
							if (indexOfMenu >= 0) {
								actualMenu.submenus.push({
									title: this.menu[key][indexOfMenu].module_title,
									key: `${this.menu[key][0].module_key}_${this.menu[key][indexOfMenu].module_key}`,
									url: this.menu[key][indexOfMenu].module_url,
									icon: this.menu[key][indexOfMenu].module_icon,
									require_auth: this.menu[key][indexOfMenu].require_auth,
									id: this.menu[key][indexOfMenu].module_id,
								})
							}
						}
					})
					if (actualMenu.submenus.length) {
						menu.push(actualMenu)
					}
				} else {
					// validamos si el módulo tiene permisos de lectura
					if (this.user.permissions[key][submenus[0]]) {
						actualMenu = {
							title: this.menu[key][0].menu_title,
							key,
							icon: this.menu[key][0].menu_icon,
							url: this.menu[key][0].menu_url,
						}
						menu.push(actualMenu)
					}
				}
			})
			this.$store.commit('CHANGE_SETTING', { setting: 'menu', value: menu })
			this.menuData = _.cloneDeep(menu)
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
