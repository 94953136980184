import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/customers/all', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function getOne(user_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/customers/${user_id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`${payload.section}`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`${payload.section}`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createRelationship(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/relationship`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggle(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`${payload.section}/toggle/${payload.id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function rfcTokenList() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/customers/listo/rfc-token-list`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function upload(formData) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/supplier-files`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFulfillment(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`${payload.section}/fulfillment`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getBlackList() {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/black-list`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getInvoices(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/invoices`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggleFiles(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/customers/supplier-files/toggle`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function search(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/search`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createProductionData(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/production-data`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateProductionData(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/customers/production-data`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createProductionPhase(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/customers/production-phases`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateProductionPhase(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/customers/production-phases`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function deleteProductionPhase(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/customers/production-phases/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function setNewPassword(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/customers/update-password`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function validateFile(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/customers/validate-file/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
