import Vue from 'vue'
import Vuex from 'vuex'
import * as warehouse from '@/services/warehouse'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'
import moment from 'moment'
import { _ } from 'core-js'
const dateFormat = 'YYYY-MM-DD'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		disabled: false,
		outboundOrders: [],
		actualOutboundOrder: {},
		filters: {
			defaultDate: [moment().startOf('day').format(dateFormat), moment().endOf('day').format(dateFormat)],
			service_order: '',
		},
		outboundType: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			warehouse
				.get()
				.then((response) => {
					commit('SET_STATE', {
						warehouseList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Punto de Venta',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						warehouseList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit, state }, id) {
			//
			commit('SET_STATE', {
				actualOutboundOrder: {},
			})
			setTimeout(() => {
				commit('SET_STATE', {
					actualOutboundOrder: _.cloneDeep(state.outboundOrders.find((e) => e.id == id)),
				})
			}, 100)
		},
		CHECKOUT({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				disabled: true,
			})
			return new Promise((resolve, reject) => {
				warehouse
					.checkout(payload)
					.then((response) => {
						Swal.fire({
							title: 'Punto de Venta',
							text: utilities.objectValidate(response, 'message', 'Venta registrada de manera correcta.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve(response)
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Punto de Venta',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
							disabled: false,
						})
					})
			})
		},
		PRODUCTION({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				disabled: true,
			})
			return new Promise((resolve, reject) => {
				warehouse
					.production(payload)
					.then((response) => {
						Swal.fire({
							title: 'Productos',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve(response)
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Productos',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
							disabled: false,
						})
					})
			})
		},
		GET_ADVANCE({ commit, rootState }, ticket) {
			rootState.pos.spinnerLoader = true
			rootState.pos.spinnerLoaderLabel = 'Obteniendo información'

			return new Promise((resolve, reject) => {
				warehouse
					.getAdvance(ticket)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Punto de Venta',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						rootState.pos.spinnerLoader = false
					})
			})
		},
		VERIFY_ODS({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			let actualOutboundOrder = rootState.products.outboundOrders.find((e) => e.service_order == id)

			if (utilities.objectValidate(actualOutboundOrder, 'service_order', false)) {
				//
				console.log('VERIFY_ODS-->')
				dispatch('products/GET_OUTBOUND_ORDER', { id: actualOutboundOrder.service_order, fromWarehouse: true }, { root: true })
				commit('SET_STATE', {
					spinnerLoader: false,
				})
			} else {
				warehouse
					.verifyODS(id)
					.then((response) => {
						if (utilities.objectValidate(response, 'data.id', false)) {
							let record = response.data

							if (rootState.products.outboundOrders.find((e) => e.service_order == record.id)) {
								rootState.products.actualOutboundOrder = _.cloneDeep(response.data)
								router.push(`/warehouse/operations/${record.id}`)
							} else {
								Swal.fire({
									title: 'Atención',
									html: `La ODS <b>${id}</b> está asignada a <b>${record.nombre} ${record.apellidos}</b>
									<br>¿Los datos de la ODS son correctos?`,
									icon: 'warning',
									showCancelButton: true,
									confirmButtonText: 'Sí, continuar',
									cancelButtonText: 'Cancelar',
								}).then((result) => {
									if (result.isConfirmed) {
										router.push('/warehouse/operations/create')
										setTimeout(() => {
											rootState.products.actualOutboundOrder = {
												service_order: id,
											}
										}, 100)
									}
								})
							}
						}
					})
					.catch((error) => {
						Swal.fire({
							title: 'Almacén',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			}
		},
		GET_OUTBOUND_ORDERS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			warehouse
				.getOutboundOrders(payload)
				.then((response) => {
					// console.log('getOutboundOrders-->', response)
					commit('SET_STATE', {
						outboundOrders: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Almacén',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async RETURN_OUTBOUND_ORDER({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				warehouse
					.returnOutboundOrders(payload)
					.then((response) => {
						// console.log('getOutboundOrders-->', response)
						Swal.fire({
							title: 'Almacén',
							text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							commit('SET_STATE', {
								actualOutboundOrder: {},
							})
							dispatch('GET_OUTBOUND_ORDERS')
						})
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Almacén',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		AUTHORIZE_OUTBOUND_ORDERS({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			warehouse
				.authorizeOutboundOrders(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_OUTBOUND_ORDERS')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		outboundOrders: (state) => state.outboundOrders,
		filters: (state) => state.filters,
		actualOutboundOrder: (state) => state.actualOutboundOrder,
		outboundType: (state) => state.outboundType,
	},
}
