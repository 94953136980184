import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/designs'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		designsList: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			return new Promise((resolve, reject) => {
				api
					.get()
					.then((response) => {
						commit('SET_STATE', {
							designsList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Diseños',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el listado'),
						})
						commit('SET_STATE', {
							designsList: [],
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			return new Promise((resolve, reject) => {
				api
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							designsList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Diseños',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							designsList: [],
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_BY_MODEL({ commit }, modelID) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			return new Promise((resolve, reject) => {
				api
					.getByModel(modelID)
					.then((response) => {
						commit('SET_STATE', {
							designsList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Diseños',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							designsList: [],
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, { payload, formData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})
			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then(async (response) => {
						if (formData) {
							formData.append('design_id', response.data.id)
							await api.uploadFile('file', formData)
						}
						Swal.fire({
							title: 'Diseños',
							text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Diseños',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async DELETE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando...',
			})
			return new Promise((resolve, reject) => {
				api
					.disable(id)
					.then(async (response) => {
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Diseños',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al eliminar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		designsList: (state) => state.designsList,
		actualRecord: (state) => state.actualRecord,
	},
}
