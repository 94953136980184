import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/pos'
import utilities from '@/services/utilities'
import moment from 'moment'
import numeral from 'numeral'
import * as facturapi from '@/services/facturapi'

const dateFormat = 'YYYY-MM-DD'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		filters: {
			defaultDate: [moment().format(dateFormat), moment().format(dateFormat)],
			cashier: null,
			paymentMethod: null,
			ticket: '',
		},
		ticketList: [],
		advancesList: [],
		totals: {
			service: 0,
			boutique: 0,
			spareParts: 0,
			advances: 0,
		},
		actualTicket: {},
		earnings: 0,
		salesCosts: 0,
		//
		tickets: [],
		bankTerminalOperations: {
			rls: {
				credit: 0,
				debit: 0,
				paymentMethods: {
					cash: 0,
					transfer: 0,
					paypal: 0,
					mercadopago: 0,
				},
			},
			pacific: {
				credit: 0,
				debit: 0,
				paymentMethods: {
					cash: 0,
					transfer: 0,
					paypal: 0,
					mercadopago: 0,
					aplazo: 0,
				},
			},
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		RESET_ONE_FILTER({ commit, dispatch, state }, key) {
			let filters = {
				...state.filters,
			}

			if (key == 'defaultDate') {
				filters.defaultDate = [moment().format(dateFormat), moment().format(dateFormat)]
			} else {
				filters[key] = 'Todos'
			}

			commit('SET_STATE', {
				filters,
			})
		},
		async GET_TICKETS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				tickets: [],
				bankTerminalOperations: {
					rls: {
						credit: 0,
						credit_msi: 0,
						debit: 0,
						paymentMethods: {
							cash: 0,
							transfer: 0,
							paypal: 0,
							mercadopago: 0,
						},
						canceled: {
							credit: 0,
							debit: 0,
							paymentMethods: {
								cash: 0,
								transfer: 0,
								paypal: 0,
								mercadopago: 0,
								aplazo: 0,
							},
						},
					},
					pacific: {
						credit: 0,
						credit_msi: 0,
						debit: 0,
						paymentMethods: {
							cash: 0,
							transfer: 0,
							paypal: 0,
							mercadopago: 0,
							aplazo: 0,
						},
						canceled: {
							credit: 0,
							debit: 0,
							paymentMethods: {
								cash: 0,
								transfer: 0,
								paypal: 0,
								mercadopago: 0,
								aplazo: 0,
							},
						},
					},
				},
			})

			return new Promise((resolve, reject) => {
				api
					.getTickets(payload)
					.then((response) => {
						let data = {
							tickets: response.data.tickets,
							bankTerminalOperations: {
								rls: {
									credit:
										numeral(response.data.bankTerminalOperations.rls.credit.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.credit.total', 0)).value(),
									credit_msi:
										numeral(response.data.bankTerminalOperations.rls.credit_msi.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.credit_msi.total', 0)).value(),
									debit:
										numeral(response.data.bankTerminalOperations.rls.debit.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.debit.total', 0)).value(),
									paymentMethods: {
										cash:
											numeral(response.data.bankTerminalOperations.rls.paymentMethods.cash.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.cash.total', 0)).value(),
										transfer:
											numeral(response.data.bankTerminalOperations.rls.paymentMethods.transfer.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.transfer.total', 0)).value(),
										paypal:
											numeral(response.data.bankTerminalOperations.rls.paymentMethods.paypal.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.paypal.total', 0)).value(),
										mercadopago:
											numeral(response.data.bankTerminalOperations.rls.paymentMethods.mercadopago.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.mercadopago.total', 0)).value(),
									},
									canceled: {
										credit: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.credit.total', 0),
										credit_msi: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.credit_msi.total', 0),
										debit: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.debit.total', 0),
										paymentMethods: {
											cash: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.cash.total', 0),
											transfer: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.transfer.total', 0),
											paypal: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.paypal.total', 0),
											mercadopago: utilities.objectValidate(response, 'data.bankTerminalOperations.rls.canceled.paymentMethods.mercadopago.total', 0),
										},
									},
								},
								pacific: {
									credit:
										numeral(response.data.bankTerminalOperations.pacific.credit.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.credit.total', 0)).value(),
									credit_msi:
										numeral(response.data.bankTerminalOperations.pacific.credit_msi.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.credit_msi.total', 0)).value(),
									debit:
										numeral(response.data.bankTerminalOperations.pacific.debit.total).value() -
										numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.debit.total', 0)).value(),
									paymentMethods: {
										cash:
											numeral(response.data.bankTerminalOperations.pacific.paymentMethods.cash.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.cash.total', 0)).value(),
										transfer:
											numeral(response.data.bankTerminalOperations.pacific.paymentMethods.transfer.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.transfer.total', 0)).value(),
										paypal:
											numeral(response.data.bankTerminalOperations.pacific.paymentMethods.paypal.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.paypal.total', 0)).value(),
										mercadopago:
											numeral(response.data.bankTerminalOperations.pacific.paymentMethods.mercadopago.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.mercadopago.total', 0)).value(),
										aplazo:
											numeral(response.data.bankTerminalOperations.pacific.paymentMethods.aplazo.total).value() -
											numeral(utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.aplazo.total', 0)).value(),
									},
									canceled: {
										credit: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.credit.total', 0),
										credit_msi: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.credit_msi.total', 0),
										debit: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.debit.total', 0),
										paymentMethods: {
											cash: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.cash.total', 0),
											transfer: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.transfer.total', 0),
											paypal: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.paypal.total', 0),
											mercadopago: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.mercadopago.total', 0),
											aplazo: utilities.objectValidate(response, 'data.bankTerminalOperations.pacific.canceled.paymentMethods.aplazo.total', 0),
										},
									},
								},
							},
						}
						commit('SET_STATE', {
							...data,
						})

						resolve(data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Ordenes de Compra',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_ONE_TICKET({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getOneTicket(id)
				.then((response) => {
					commit('SET_STATE', {
						actualTicket: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Ordenes de Compra',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DOWNLOAD_INVOICE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			facturapi
				.downloadPDF(id)
				.then((response) => {
					window.open(`${process.env.VUE_APP_API_URL}/${response.data}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la factura'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		REQUEST_AUTHORIZATION({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Confirmando...',
			})
			return new Promise((resolve, reject) => {
				api
					.requestAuthorization(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(utilities.objectValidate(error, 'response.data.message', 'Contraseña inválida.'))
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CLOSE_ADVANCE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Confirmando...',
			})
			return new Promise((resolve, reject) => {
				api
					.closeAdvanceTicket(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(utilities.objectValidate(error, 'response.data.message', 'Contraseña inválida.'))
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_PDF_REPORT({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo reporte...',
			})
			return new Promise((resolve, reject) => {
				api
					.getPDFReport(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(utilities.objectValidate(error, 'response.data.message', 'Error al obtener el reporte.'))
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		filters: (state) => state.filters,
		ticketList: (state) => state.ticketList,
		actualTicket: (state) => state.actualTicket,
		advancesList: (state) => state.advancesList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		earnings: (state) => {
			let total = 0
			state.ticketList.map((e) => {
				let cart = JSON.parse(e.cart)
				cart.map((el) => {
					total += (numeral(el.product.normal_price).value() - numeral(el.product.unit_cost).value()) * el.quantity
				})
			})
			return total
		},
		salesCosts: (state) => {
			let total = 0
			state.ticketList.map((e) => {
				let cart = JSON.parse(e.cart)
				cart.map((el) => {
					// sólo se considera en la sumatoria el valor de productos y no de servicios
					if (el.product.measurement_unit == 'H87') {
						total += numeral(el.product.unit_cost).value() * el.quantity
					}
				})
			})
			return total
		},
		totals: (state) => state.totals,
		tickets: (state) => state.tickets,
		bankTerminalOperations: (state) => state.bankTerminalOperations,
	},
}
