import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/warehouse')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function checkout(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/warehouse/checkout', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getAdvance(ticket) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/warehouse/advance/${ticket}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function verifyODS(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/warehouse/ods/verify/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function getOutboundOrders(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/warehouse/outbound-orders`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function returnOutboundOrders(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/warehouse/return-outbound-orders`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function authorizeOutboundOrders(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/products/authorize-outbound-orders`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function production(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/warehouse/production`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
